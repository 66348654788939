<template>
  <ReporteTransaccionesTotalesMensuales
    titulo="Reportes - Compras Totales Mensuales"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="compra"
  />
</template>

<script>
import ReporteTransaccionesTotalesMensuales from "../../components/ReporteTransaccionesTotalesMensuales.vue";
import ReporteComprasService from "../services";

export default {
  name: "ReporteComprasTotalesMensualesLayout",
  components: {
    ReporteTransaccionesTotalesMensuales,
  },
  data() {
    return {};
  },
  methods: {
    servicioGetReporte: ReporteComprasService.getReporteComprasTotalesMensuales,
    servicioGetReportePdf:
      ReporteComprasService.getReporteComprasTotalesMensualesPdf,
  },
};
</script>